$helpers = {};

$helpers.isObject = function (value) {
  return (typeof value === 'object' && value !== null && !Array.isArray(value));
};

$helpers.ucwords = function (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

$helpers.prefixChar = function (str, char, repeat) {
  let prefix = '';
  for (let i = 0; i < repeat; ++i) {
    prefix += char;
  }
  return prefix + str;
};

$helpers.formatSendingMessage = function (message, level) {
  if (!$helpers.isObject(message)) {
    return `${message}`.replaceAll('<br/>', '\n').replaceAll('<b>', '*').replaceAll('</b>', '*');
  }
  level = level || 0;
  let str = '';
  for (let key in message) {
    if (key === 'trace') {
      continue;
    }
    const formatedKey = $helpers.ucwords(key.replaceAll('_', ' '));
    const value = $helpers.formatSendingMessage(message[key], level + 1);
    str += '\n' + $helpers.prefixChar(`- *${formatedKey}:* ${value}`, ' ', level * 4);
  }
  return str;
};

$helpers.formatSendingMessage = function (message, level) {
  if (!$helpers.isObject(message)) {
    return `${message}`.replaceAll('<br/>', '\n').replaceAll('<b>', '*').replaceAll('</b>', '*');
  }
  level = level || 0;
  let str = '';
  for (let key in message) {
    if (key === 'trace') {
      continue;
    }
    const formatedKey = $helpers.ucwords(key.replaceAll('_', ' '));
    const value = $helpers.formatSendingMessage(message[key], level + 1);
    str += '\n' + $helpers.prefixChar(`- *${formatedKey}:* ${value}`, ' ', level * 4);
  }
  return str;
};

$helpers.htmlToPlainText = function (html, formatWhatsApp) {
  // Replace <b>, <i> and <br> tags
  const bold = formatWhatsApp ? '*' : '';
  const italic = formatWhatsApp ? '_' : '';
  html = html.replaceAll('<b>', bold).replaceAll('</b>', bold);
  html = html.replaceAll('<i>', italic).replaceAll('</i>', italic);
  html = html.replaceAll('<br>', '\n').replaceAll('<br/>', '\n');
  // Create a temporary div element
  var tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  // Process ordered lists
  var listElements = tempDiv.getElementsByTagName('ol');
  for (let elem of listElements) {
    let items = elem.getElementsByTagName('li');
    let counter = 1;
    for (let item of items) {
      item.textContent = (counter++).toString() + '. ' + item.textContent + '\n';
    }
  }
  // Process unordered lists
  listElements = tempDiv.getElementsByTagName('ul');
  for (let elem of listElements) {
    let items = elem.getElementsByTagName('li');
    for (let item of items) {
      item.textContent = '- ' + item.textContent + '\n';
    }
  }
  // Replace certain tags with double line breaks
  var replaceWithLineBreaks = ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  replaceWithLineBreaks.forEach(tag => {
    let elements = tempDiv.getElementsByTagName(tag);
    for (let elem of elements) {
      elem.textContent = '\n\n' + elem.textContent + '\n\n';
    }
  });
  // Get the text content of the temporary div
  var text = tempDiv.textContent;
  // Remove any remaining HTML tags
  text = text.replace(/<[^>]*>?/gm, '');
  // Remove more than 2 consecutive line breaks
  text = text.replace(/\n{3,}/g, '\n\n');
  return text.trim();
}

$helpers.sendToWhatsApp = function (phoneNumber, message, title) {
  phoneNumber = phoneNumber.replace(/\D+/g, '');
  const url = `/wapp/send/${phoneNumber}?t=`;
  var text = title ? `*${title}*\n\n` + $helpers.htmlToPlainText(message, true) : $helpers.htmlToPlainText(message, true);
  text = text.replaceAll('#', '@@@@@');
  text = encodeURI(text).replaceAll('@@@@@', '%23').replaceAll('@LOCK@', '%F0%9F%94%93');
  window.open(url + text, '_blank');
};

$helpers.copyToClipBoard = async (text, callback) => {
  text = $helpers.htmlToPlainText(text);
  text = text.replaceAll('@LOCK@', '🔓');
  await navigator.clipboard.writeText(text);
  if (callback) {
    callback();
  }
};

$helpers.setupWYSIWYG = function(fields, basic){
  const settingsStandard = {
    toolbar: [
      ['style', ['bold', 'italic']],
      ['insert', ['link', 'picture']],
      ['para', ['ul', 'ol']],
      ['view', ['fullscreen', 'codeview']],
    ]
  };
  const settingsBasic = {
    toolbar: [
      ['style', ['bold', 'italic', 'underline']],
      ['para', ['ul', 'ol']],
      ['view', ['codeview']],
    ]
  };
  const reset = function () {
    $(this).summernote('code', this.value);
  };
  const setupFields = function () {
    fields.forEach((field) => {
      if (field.getAttribute('data-wysiwyg') !== '1') {
        $(field).summernote(basic ? settingsBasic : settingsStandard);
        $(field).on('change', reset);
        field.setAttribute('data-wysiwyg', '1')
      }
    });
  };
  // Link Tag
  if (!document.getElementById('script-summernote-lite')) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.id = 'styles-summernote-lite';
    link.href = 'https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-lite.min.css';
    document.head.append(link);
  }
  // Script Tag
  if (!document.getElementById('script-summernote-lite')) {
    const script = document.createElement('script');
    script.addEventListener('load', setupFields);
    script.id = 'script-summernote-lite';
    script.src = 'https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-lite.min.js';
    document.body.append(script);
    return;
  } else {
    setupFields();
  }
};