// Create Script Tag
var script = document.createElement('script');
script.src = 'https://www.googletagmanager.com/gtag/js?id=G-CWM523CEXV';
script.async = true;
document.head.appendChild(script);

// Setup GTag Datalayer
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());
gtag('config', 'G-CWM523CEXV');